import { _union, _intersection, _difference, _symmetricDifference, _isSubsetOf, _isSupersetOf, _isDisjointFrom } from "./library.js";
//========== Polyfill ==========
function installPolyfill() {
    const funcs = [
        _union,
        _intersection,
        _difference,
        _symmetricDifference,
        _isSubsetOf,
        _isSupersetOf,
        _isDisjointFrom,
    ];
    for (const func of funcs) {
        Object.defineProperty(Set.prototype, func.name.slice(1), // remove prefixed underscore
        {
            value: func,
            writable: true,
            enumerable: false,
            configurable: true
        });
    }
}
installPolyfill();
